<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>

* { margin: 0; padding: 0; }
img { vertical-align: bottom; }
li { list-style: none; }
.pointer { cursor: pointer; }
.flex { display: flex; }
.flex-column { display: flex; flex-direction: column; }
.center { justify-content: center; align-items: center; }
.center-j { justify-content: center; }
.center-a { align-items: center; }
.mask { position: absolute; top: 0; bottom: 0; left: 0; right: 0; }
</style>
